<template>
  <div class="modifyMarginPwd">
    <!-- <div class="modifyMarginPwdTitle">
      <div class="myModifyMarginPwd">
        <p>设置资金密码</p>
      </div>
    </div> -->
    <div v-if="isShowSetPassword === 0" class="modifyMarginPwdContent">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item label="新密码" prop="newMarginPwd">
          <el-input v-model="form.newMarginPwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="rePwd">
          <el-input v-model="form.rePwd" type="password"></el-input>
        </el-form-item>
        <div class="submitButton">
          <el-button type="danger" @click="submitApply()">提交</el-button>
        </div>
      </el-form>
    </div>
    <div v-if="isShowSetPassword === 1">
      <el-alert
        title="您已设置资金密码"
        type="success"
        :closable="false">
      </el-alert>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.newMarginPwd) {
        callback(new Error("确认密码和新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      isShowSetPassword: null,
      form: {
        newMarginPwd: "",
        rePwd: ""
      },
      rules: {
        newMarginPwd: [
          { required: true, message: "请输入新密码", trigger: "blur" }
        ],
        rePwd: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePassCheck, trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.checkPwd();
  },
  methods: {
    checkPwd() {
      http.postFront(protocolFwd.param_findFundPwd).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.isShowSetPassword = value
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    submitApply() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_setMarginPwd.param.newPassWord =  md5('47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' + this.form.newMarginPwd);
          http.postFront(protocolFwd.param_setMarginPwd).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$refs['form'].resetFields();
              this.checkPwd();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.modifyMarginPwd {
  padding: 0 15px;
}
.modifyMarginPwd .myModifyMarginPwd {
  float: left;
  line-height: 40px;
}
.modifyMarginPwdTitle {
  overflow: hidden;
}
.modifyMarginPwdTitle .myModifyMarginPwd p {
  font-size: 16px;
}
.modifyMarginPwdContent {
  width: 80%;
}
</style>

